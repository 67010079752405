import dynamic from "next/dynamic"
import Slider from "@/common/slider/slider"
import Block from "@/components/wrappers/block"
import styles from "./post-slider.module.css"
import cx from "classnames"

const Cards = {
  post: dynamic(() => import("@/common/cards/article/article")),
  event: dynamic(() => import("@/common/cards/event/event"))
}

export default function PostSlider({ block }) {
  const { list, heading, text, theme, image, button, type } = block.attrs

  if (list.length === 0) {
    return null
  }

  const wrapperClasses = [
    styles.postSlider,
    styles[theme],
    theme === "image" ? styles.overlay : ""
  ]
  let backgroundImage = {}

  if (image) {
    backgroundImage = { backgroundImage: "url(" + image.url + ")" }
  }

  return (
    <Block
      block={block}
      width="bleed"
      className={cx(wrapperClasses)}
      style={backgroundImage}>
      <Slider
        id={block.attrs.context}
        heading={heading}
        text={text}
        theme={theme}
        button={button}>
        {list.map((post, index) => {
          const Card = Cards[type] ?? NotFound
          return <Card key={index} post={post} />
        })}
      </Slider>
    </Block>
  )
}

const NotFound = () => {
  return null
}
